<template>
  <div class="label-online">
    Online
  </div>
</template>

<script>
export default {
  name: "AppLabelOnline"
};
</script>

<style scoped></style>
